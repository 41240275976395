import { createStyles, makeStyles } from '@material-ui/styles';
import { UsersResponse } from '@myneva-portals/models/src/models/user/users-response';
import { ContentBar, UsersList } from '@myneva-portals/projects/src/components';
import { RestUsersService } from '@myneva-portals/services/src/services';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import {
  LoadingWrapper,
  PrimaryButton,
  SearchParams,
  showToast,
  ErrorTimeout,
} from 'front-components/src/components';
import { Link, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  defaultErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';
import {
  adminsSelector,
  updateAdmins,
  updateLimit,
  updateSearchParams,
  updateTotalCount,
} from '../../state/reducers/admins';
import { navigationSelector } from '../../state/reducers/navigation';

export const AdminsContent: React.FC = () => {
  const url = process.env.WEB_GATEWAY_URL + '/core/';
  const userService = new RestUsersService(url);

  const useStyles = makeStyles(
    () =>
      createStyles({
        appLink: {
          textDecoration: 'none',
        },
      }),
    { name: 'AdminContent' }
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const redirectToastConfig = redirectErrorToast();

  const [isSessionError, setIsSessionError] = React.useState(false);

  const { breadcrumbs } = useSelector(navigationSelector);
  const { admins, searchParams } = useSelector(adminsSelector);

  const handleEdit = (id: string, isEdit: boolean) => {
    navigate(`/users/${id}`, { state: { isEdit } });
  };
  interface IToggleParams {
    id: string;
    isEnabled: boolean;
  }
  const { mutate: deleteAdmin } = useMutation(
    (id: string) => userService.deleteUser(id).then(() => refetch()),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const { mutate: toogleAdminStatus } = useMutation(
    (params: IToggleParams) =>
      userService
        .toggleUserStatus(params.id, params.isEnabled)
        .then(() => refetch()),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const handleDelete = async (id: string) => {
    deleteAdmin(id);
  };

  const handleToggleStatus = async (id: string, isEnabled: boolean) => {
    const params: IToggleParams = { id, isEnabled };
    toogleAdminStatus(params);
  };

  const handleSearchParamsChange = (params: SearchParams) => {
    dispatch(updateSearchParams(params));
    userService.getUsers(params).then((data) => {
      dispatch(updateAdmins(data.users));
      dispatch(updateTotalCount(data.totalCount));
    });
  };

  const actionPanel = (
    <div>
      <Link className={classes.appLink} to={'/users/add-core-admin'}>
        <PrimaryButton
          label={t('ADD_USER_HEADER')}
          size={'small'}
          cyName={'users-add-user-button'}
        />
      </Link>
    </div>
  );

  const content = (
    <div>
      <ContentBar
        translation={t}
        breadcrumbsItems={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
        actionPanelContent={actionPanel}
      />
      <UsersList
        users={admins}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onToggleStatus={handleToggleStatus}
        translation={t}
        searchParams={searchParams}
        onSearchParamsChange={(searchParams: SearchParams) =>
          handleSearchParamsChange(searchParams)
        }
        contentType={'users'}
      ></UsersList>
    </div>
  );

  const { error, data, refetch, isLoading } = useQuery(
    'getAdminsQuery',
    () =>
      userService.getUsers(searchParams).then((data: UsersResponse) => data),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(updateAdmins(data.users));
      if (
        searchParams.pagination.totalCount === searchParams.pagination.limit
      ) {
        dispatch(updateLimit(data.totalCount));
      }
      dispatch(updateTotalCount(data.totalCount));
    }
  }, [data]);

  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <div>
      <LoadingWrapper
        content={error ? errorComponent : content}
        isLoading={isLoading || isSessionError}
        useLineProgress={true}
      ></LoadingWrapper>
    </div>
  );
};

import * as React from 'react';

import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';

import { PortalLayout } from 'front-components/src/components';

import { AdminMenu } from '../components/asside-menu/asside-menu';
import { PortalHeader } from '../components/portal-header/portal-header';
import { AdminsContent } from '../content/admins/admins';

const AdminsPage = () => {
  const asside = <AdminMenu />;
  const content = <AdminsContent />;

  return (
    <main>
      <title>Admins Page</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        {content}
      </PortalLayout>
    </main>
  );
};

export default AdminsPage;
